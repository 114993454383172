import { executeAxios, handleAlert } from 'core/services/actions';
import { isRequiredArray } from 'core/services/form/validation/form.validation';

export const postForm = async data => {
	try {
		const response = await executeAxios({
			url: '/feedback/not-present',
			method: 'POST',
			data,
		});
		handleAlert('success', 'Formulier is succesvol verstuurd');
		return response;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		throw error;
	}
};

export const notPresentForm = type => {
	return {
		feedback: {
			type: 'radio',
			name: 'feedback',
			label: 'Wat is de reden dat je niet aanwezig zal zijn?',
			options: [
				...(type !== 'events'
					? [
							{
								value: 'intrest',
								label: 'Geen interesse het onderwerp',
							},
							{
								value: 'knowledge',
								label: 'Reeds voldoende kennis over het onderwerp',
							},
						]
					: []),

				{
					value: 'afterword',
					label: `Liever geen ${type === 'events' ? 'evenementen' : 'opleidingen'} na de werkuren`,
				},
				{ value: 'personal', label: 'Privé- of gezinsverplichtingen' },
				{ value: 'location', label: 'Locatie is te ver' },

				...(type !== 'events'
					? [
							{
								value: 'method',
								label: 'Voorkeur voor andere leermethoden',
							},
						]
					: []),

				{ value: 'not_say', label: 'Ik zeg dit liever niet' },
			],
			errors: [],
			required: true,
			description:
				'We streven ernaar om in de toekomst beter evenementen en opleidingen te kunnen organiseren die bij jouw interesses en wensen aansluiten. Jouw feedback is daarom cruciaal. Meerdere opties zijn mogelijk.',
			validations: [isRequiredArray],
			value: [],
		},
	};
};
