import MainFeed from 'components/MainFeed/MainFeed';
import NextEvents from 'components/NextEvents/NextEvents';

const Home = () => {
	return (
		<>
			<NextEvents />
			<MainFeed />
		</>
	);
};

export default Home;
