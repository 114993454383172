import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	nextEvents: [],
};

const loadNextEvents = (state, { data }) => ({ ...state, nextEvents: data });

export const nextEventsReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.NEXT_EVENTS_LOAD:
			return loadNextEvents(state, payload);
		default:
			return state;
	}
};
