import { isRequired } from 'core/services/form';

export const createFindAbsenceType = absenceTypes => id => {
	return absenceTypes ? absenceTypes.find(type => type.id === id) : null;
};

export const durationField = {
	type: 'number',
	label: 'Uren',
	name: 'duration',
	value: '',
	errors: [],
	validations: [isRequired],
	required: true,
};

export const typeField = {
	type: 'select',
	label: 'Type',
	name: 'absenceTypeId',
	value: '',
	required: true,
	errors: [],
	validations: [isRequired],
	options: [],
};

/**
 * Create the options for the select field.
 * @param {[]} absenceTypes
 * @returns {{label: string, value: number}[]} The options for the select field.
 */
export const createTypeOptions = absenceTypes => {
	return absenceTypes
		.filter(
			type =>
				!type.autoAssigned &&
				type.machineName !== AbsenceTypes.ILLNESS &&
				type.machineName !== AbsenceTypes.ILLNESS_MONTH &&
				type.machineName !== AbsenceTypes.ILLNESS_YEAR &&
				type.machineName !== AbsenceTypes.ILLNESS_HALF_YEAR,
		)
		.map(type => ({
			label: type.name,
			value: type.id,
		}));
};

/**
 * Create the validation function for the given absence.
 * @param {*} absence
 * @param {*} absences
 * @param {*} maxHours
 * @returns {(val: string) => string} The validation function.
 * @example
 * const absence = { id: 1, duration: '2' };
 * const absences = [{ id: 1, duration: '2' }, { id: 2, duration: '3' }];
 * const maxHours = 8;
 * const validation = createValidation(absence, absences, maxHours);
 * const error = validation('5');
 * // error = 'De totaal uren mogen niet meer dan 6 uur zijn';
 * const error2 = validation('3');
 * // error2 = '';
 * const error3 = validation('-1');
 * // error3 = 'De uren mogen niet minder dan 0 zijn';
 * const error4 = validation('9');
 * // error4 = 'De totaal uren mogen niet meer dan 6 uur zijn';
 * const error5 = validation('8');
 */
export const createValidation = (absence, absences, maxHours) => {
	const totalDuration = absences.reduce(
		(total, { id, duration }) =>
			total + (id === absence.id ? 0 : parseFloat(duration)),
		0,
	);
	const remainingHours = maxHours - parseFloat(totalDuration);

	const periodCheck = val => {
		const parsedValue = parseFloat(val || 0);

		if (parsedValue <= 0) {
			return 'De uren mogen niet minder dan 0 zijn';
		}
		if (parsedValue > remainingHours) {
			return `De totaal uren mogen niet meer dan ${remainingHours} uur zijn`;
		}
		return '';
	};
	return periodCheck;
};

/**
 * Create the absence period for the given date.
 * @param {*} absences
 * @param {*} absenceTypes
 * @param {*} date
 * @returns {*} The absence period for the given date.
 * @example
 * const absences = [{ id: 1, date: '2021-01-01', absenceTypeId: 1 }];
 * const absenceTypes = [{ id: 1, machineName: 'illness' }];
 * const date = '2021-01-01';
 * const period = createAbsencePeriod(absences, absenceTypes, date);
 * // period = [{ id: 1, date: '2021-01-01', absenceTypeId: 1, type: 'illness' }];
 */
export const createAbsencePeriod = (absences, absenceTypes, date) => {
	return absences
		.filter(absence => absence.date === date)
		.map(absence => ({
			...absence,
			type: absenceTypes.find(type => type.id === absence.absenceTypeId)
				?.machineName,
		}));
};

export const AbsenceTypes = Object.freeze({
	ILLNESS: 'illness',
	ILLNESS_MONTH: 'illness-month',
	ILLNESS_HALF_YEAR: 'illness-half-year',
	ILLNESS_YEAR: 'illness-year',
	ABSENCE: 'absence',
	LEAVE_OF_ABSENCE: 'leave-of-absence',
	BANK_HOLIDAY: 'bank-holiday',
	ADV: 'adv',
	OVERTIME: 'overtime',
	SENIORITY_LEAVE: 'seniority-leave',
	RECOVERY_LEAVE: 'recovery-leave',
	SHORT_LEAVE: 'short-leave',
	SOCIAL_LEAVE: 'social-leave',
	TIME_CREDIT: 'time-credit',
	EDUCATIONAL_LEAVE: 'educational-leave',
	FLEMISH_EDUCATIONAL_LEAVE: 'flemish-educational-leave',
	YOUTH_HOLIDAY: 'youth-holiday',
	MATERNITY_LEAVE: 'maternity-leave',
	PATERNITY_LEAVE: 'paternity-leave',
	PARENTAL_LEAVE: 'parental-leave',
	APPLICATION_LEAVE: 'application-leave',
	UNPAID_LEAVE: 'unpaid-leave',
	OTHER: 'other',
});
