import { combineReducers } from 'redux';

import { alertReducer } from './alerts/alerts.reducer';
import { applicationReducer } from './application/application.reducer';
import { authReducer } from './authentication/auth.reducer';
import { calendarReducer } from './calendar/calendar.reducer';
import { carReducer } from './cars/car.reducer';
import { cocesReducer } from './coces/coces.reducer';
import { departmentsReducer } from './departments/departments.reducer';
import { eventReducer } from './events/events.reducer';
import { inviteReducer } from './invites/invite.reducer';
import { mainFeedReducer } from './mainFeed/mainFeed.reducer';
import { mediaReducer } from './media/media.reducer';
import { movingMissionReducer } from './movingMission/movingMission.reducer';
import { newsFeedReducer } from './newsFeed/newsFeed.reducer';
import { nextEventsReducer } from './nextEvents/next-events.reducer';
import { notificationReducer } from './notifications/notifications.reducer';
import { snackbarReducer } from './snackbar/snackbar.reducer';
import { stickerReducer } from './stickers/sticker.reducer';
import { subFeedReducer } from './subFeed/subFeed.reducer';
import { tagReducer } from './tags/tag.reducer';
import { toppingReducer } from './toppings/topping.reducer';
import { trainingReducer } from './trainings/trainings.reducer';
import { usersReducer } from './users/users.reducer';
import { wellbeingReducer } from './wellbeing/wellbeing.reducer';

export default combineReducers({
	applicationReducer,
	authReducer,
	cocesReducer,
	departmentsReducer,
	eventReducer,
	mainFeedReducer,
	mediaReducer,
	newsFeedReducer,
	snackbarReducer,
	stickerReducer,
	alertReducer,
	notificationReducer,
	subFeedReducer,
	tagReducer,
	trainingReducer,
	usersReducer,
	carReducer,
	toppingReducer,
	inviteReducer,
	wellbeingReducer,
	movingMissionReducer,
	calendarReducer,
	nextEventsReducer,
});
