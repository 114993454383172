import { useState } from 'react';
import {
	faCirclePlus,
	faPen,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { getTimeInFormat } from 'core/services/parse';
import {
	arrayOf,
	bool,
	func,
	number,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import { Button, InputField, Select, Textarea, useForm } from 'components/Form';
import { Col, Row } from 'components/Grid';
import Modal from 'components/Modal/Modal';

import {
	createAbsence,
	deleteAbsence,
	updateAbsence,
} from '../vacation.service';

import {
	AbsenceTypes,
	createAbsencePeriod,
	createFindAbsenceType,
	createTypeOptions,
	createValidation,
	durationField,
	typeField,
} from './vacation.modal.helper';

import './VacationModal.scss';

const VacationModal = ({
	modal,
	setModal,
	setAbsences,
	absenceTypes,
	absences,
	notation,
}) => {
	const { date, getMaxHours } = modal;

	const absencePeriod = createAbsencePeriod(absences, absenceTypes, date);

	const [selectedAbsence, setSelectedAbsence] = useState(null);

	const { register, isValid, isDirty } = useForm();

	const findAbsenceType = createFindAbsenceType(absenceTypes);

	const illness = absencePeriod.find(absence => {
		return (
			absence.type === AbsenceTypes.ILLNESS ||
			absence.type === AbsenceTypes.ILLNESS_MONTH ||
			absence.type === AbsenceTypes.ILLNESS_HALF_YEAR ||
			absence.type === AbsenceTypes.ILLNESS_YEAR
		);
	});

	const handleChange = absence => {
		setSelectedAbsence(absence);
	};

	const handleDelete = async absence => {
		const deleted = await deleteAbsence(absence);
		if (!deleted) return;
		setAbsences(prev => prev.filter(({ id }) => absence.id !== id));
	};

	const handleSave = async () => {
		if (!isValid()) return;
		const updatedAbsence = await updateAbsence(selectedAbsence);
		if (!updatedAbsence) return;
		setAbsences(prev => [
			...prev.filter(absence => absence.id !== selectedAbsence.id),
			{ ...updatedAbsence, value: +updatedAbsence.duration },
		]);
		setSelectedAbsence(null);
	};

	const handleAdd = async () => {
		const createdAbsence = await createAbsence(date);
		if (!createdAbsence) return;
		setAbsences(prev => [
			...prev,
			{ ...createdAbsence, value: +createdAbsence.duration },
		]);
	};

	const totalDuration = absencePeriod.reduce(
		(total, absence) => total + parseFloat(absence.duration),
		0,
	);

	const typeOptions = createTypeOptions(absenceTypes);

	return (
		<Modal
			show={!!modal}
			close={() => setModal(null)}
			title={`${toShortDate(date)}: afwezigheid wijzigen`}
			cancel={false}
			actions={[
				{
					label: 'Verlaten',
					styles: { marginLeft: '1rem' },
					buttonType: 'secondary',
					action: () => setModal(null),
				},
			]}>
			<div className='vacation-modal'>
				<h3>
					op {toShortDate(date)} werden volgende gegevens
					geregistreerd:
				</h3>
				<div className='vacation-modal__absences'>
					{illness ? (
						<div className='vacation-modal__illness'>
							<span className='vacation-modal__illness__item'>
								Ziekte:{' '}
								{getTimeInFormat(notation, illness.duration)}
							</span>
							<span className='vacation-modal__illness__info'>
								(bewerk via ziektemelding)
							</span>
						</div>
					) : null}
					{absencePeriod
						.filter(
							absence =>
								absence.type !== AbsenceTypes.ILLNESS &&
								absence.type !== AbsenceTypes.ILLNESS_MONTH &&
								absence.type !==
									AbsenceTypes.ILLNESS_HALF_YEAR &&
								absence.type !== AbsenceTypes.ILLNESS_YEAR,
						)
						.map(absence => {
							const type = findAbsenceType(absence.absenceTypeId);
							return selectedAbsence?.id !== absence.id ? (
								<div
									className='vacation-modal__absence'
									key={`${absence.id}-${type?.name}`}>
									<div className='vacation-modal__absence__item'>
										{type?.name}:{' '}
										{getTimeInFormat(
											notation,
											absence.duration,
										)}
									</div>
									<div className='vacation-modal__absence__controls'>
										<span
											onClick={() =>
												handleChange(absence)
											}
											className='vacation-modal__absence__control'>
											<FontAwesomeIcon
												icon={faPen}
												fixedWidth
											/>
										</span>
										<span
											onClick={() =>
												handleDelete(absence)
											}
											className='vacation-modal__absence__control'>
											<FontAwesomeIcon
												icon={faTrash}
												fixedWidth
											/>
										</span>
									</div>
								</div>
							) : null;
						})}
					{totalDuration < 8 && !selectedAbsence ? (
						<div
							className='vacation-modal__absence--add'
							onClick={handleAdd}>
							<FontAwesomeIcon
								icon={faCirclePlus}
								size='lg'
								fixedWidth
							/>
						</div>
					) : null}
				</div>
				{selectedAbsence ? (
					<Row>
						<Col md={typeOptions.length > 1 ? 4 : 6}>
							<InputField
								{...register('duration', {
									...durationField,
									value: selectedAbsence.duration,
									validations: [
										createValidation(
											selectedAbsence,
											absencePeriod,
											getMaxHours(),
										),
									],
									onChange: value => {
										setSelectedAbsence(prev => ({
											...prev,
											duration: +value,
										}));
									},
								})}
							/>
						</Col>
						{typeOptions.length > 1 ? (
							<Col md={4}>
								<Select
									{...register('absenceTypeId', {
										...typeField,
										value: selectedAbsence.absenceTypeId,
										options: typeOptions,
										onChange: value => {
											setSelectedAbsence(prev => ({
												...prev,
												absenceTypeId: value,
											}));
										},
									})}
								/>
							</Col>
						) : null}
						<Col md={typeOptions.length > 1 ? 4 : 6}>
							<Textarea
								{...register('remark', {
									label: 'Opmerking',
									rows: 1,
									value: selectedAbsence.remark || '',
									onChange: value => {
										setSelectedAbsence(prev => ({
											...prev,
											remark: value,
										}));
									},
								})}
							/>
						</Col>
						<Col md={12}>
							<div
								className='btn__group full--flex'
								style={{ marginTop: '2rem' }}>
								<Button
									buttonStyle='primary'
									buttonSize='small'
									onClick={handleSave}
									disabled={isDirty() ? !isValid() : true}>
									Bewaar
								</Button>
								<Button
									buttonStyle='secondary'
									buttonSize='small'
									onClick={() => {
										setSelectedAbsence(null);
									}}>
									Annuleren
								</Button>
							</div>
						</Col>
					</Row>
				) : null}
				{selectedAbsence ? (
					<div className='vacation-modal__info'>
						{selectedAbsence.type ===
						AbsenceTypes.EDUCATIONAL_LEAVE ? (
							<p>Opleiding</p>
						) : (
							<p>
								Feestdagen, verlof, ADV en ancieniteitsverlof
								worden automatisch toegewezen op basis van
								beschikbaarheid. Kies afwezigheid om een van
								deze aan te vragen.
							</p>
						)}
					</div>
				) : null}
			</div>
		</Modal>
	);
};

VacationModal.propTypes = {
	modal: shape({
		date: string.isRequired,
	}),
	setModal: func.isRequired,
	setAbsences: func.isRequired,
	absences: arrayOf(
		shape({
			id: number.isRequired,
			absenceTypeId: number.isRequired,
			date: string.isRequired,
			duration: number.isRequired,
			locked: oneOfType([number, bool]),
			signed: oneOfType([number, bool]),
			remark: string,
		}),
	).isRequired,
	absenceTypes: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			machineName: string.isRequired,
			isOther: number.isRequired,
			autoAssigned: number.isRequired,
			availableHourly: number.isRequired,
		}),
	).isRequired,
};

export default VacationModal;
