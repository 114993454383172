import { useState } from 'react';
import { useSubmit } from 'core/hooks/useSubmit';

import { InputCheckboxGroup, useForm } from 'components/Form';
import Modal from 'components/Modal/Modal';

import { notPresentForm, postForm } from './not-present.helper';

const NotPresent = ({ modal, entity, setModal, type }) => {
	const [fields] = useState(notPresentForm(type));

	const { register, values, isValid } = useForm();

	const handleSubmit = useSubmit(async () => {
		const payload = {
			type: type,
			id: entity.id,
			results: values,
		};

		const posted = await postForm(payload);
		if (posted) {
			if (modal?.postSubmitCallback) {
				modal.postSubmitCallback();
			}
			setModal(null);
		}
	});

	return (
		<Modal
			show={modal}
			close={() => setModal(null)}
			title={entity.name}
			actions={[
				{
					label: 'Verstuur',
					styles: { marginLeft: '1rem' },
					disabled: !isValid(),
					action: handleSubmit,
				},
			]}>
			<InputCheckboxGroup {...register('feedback', fields.feedback)} />
		</Modal>
	);
};

export default NotPresent;
