import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toShortDate } from 'core/services/date';
import { loadNextEvent } from 'core/store/nextEvents/next-events.actions';

import Card from 'components/Card/Card';

import { Col, Row } from '../Grid';

import './NextEvents.scss';

const NextEvents = () => {
	const { nextEvents } = useSelector(state => state.nextEventsReducer);

	useEffect(() => {
		loadNextEvent();
	}, []);

	return nextEvents?.length ? (
		<Row className='upcoming'>
			{nextEvents.map(x => (
				<Col xl={4} key={x.id}>
					<Card title={x.name} showPlaceholder={false}>
						<Link
							to={`/${x.type === 'trainings' ? 'opleidingen' : 'evenementen'}/${x.slug}`}>
							<div className='content'>
								<b>Datum:</b> {toShortDate(new Date(x.date))}
							</div>
							<div className='content'>
								<b>Uur:</b> {x.startTime} - {x.endTime}
							</div>
							{x.location ? (
								<div className='content'>
									<b>Locatie:</b>{' '}
									<span
										dangerouslySetInnerHTML={{
											__html: x.location.replace(
												', ',
												'<br />',
											),
										}}
									/>
								</div>
							) : null}
						</Link>
					</Card>
				</Col>
			))}
		</Row>
	) : null;
};

export default NextEvents;
