import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadNextEvent = () => {
	dispatchAction({
		type: ACTION_TYPES.NEXT_EVENTS_LOAD,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/main-feed/events`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.NEXT_EVENTS_LOAD },
				rollback: {},
			},
		},
	});
};
