import { array, bool, func, string } from 'prop-types';

import { InputCheckbox } from '../index';
import useFormInput from '../useFormInput';

import './InputCheckboxGroup.scss';

const InputCheckboxGroup = ({
	name,
	label = '',
	description = '',
	options,
	value,
	disabled = false,
	required = false,
	readOnly = false,
	validations = [],
	validate,
	errors = [],
	onChange,
	className,
}) => {
	const { touched, focused, id, ...handlers } = useFormInput({
		name,
		errors,
		onChange,
		validate,
		validations,
	});

	const handleClick = (v, n) => {
		if (value.includes(n) && !v) {
			value = value.filter(x => x !== n);
		} else if (v) {
			value.push(n);
		}

		onChange(value);
	};

	return (
		<div
			className={`checkbox-button${
				focused ? ' checkbox-button--focused' : ''
			}  ${className ? className : ''}`}>
			<div
				className={`checkbox-button__control${
					errors.length === 0 || !touched
						? ''
						: ' checkbox-button--invalid'
				}`}>
				<label className='radio-button__label' htmlFor={id}>
					{label} {required ? null : <code>(opt.)</code>}
				</label>
				<div className='checkbox-button__options' tabIndex='1'>
					{options.map(option => (
						<InputCheckbox
							name={id + '-' + option.value}
							onChange={value => handleClick(value, option.value)}
							value={value.includes(option.value)}
							onBlur={handlers.onBlur}
							onFocus={handlers.onFocus}
							label={option.label}
							type='checkbox'
							disabled={option.disabled || disabled}
							readOnly={readOnly}
							key={option.value}
						/>
					))}
				</div>
			</div>

			{errors.length > 0 && touched ? (
				<div className='checkbox-button__invalid-feedback'>
					{errors[0]}
				</div>
			) : (
				<div className='radio-button__description'>{description}</div>
			)}
		</div>
	);
};

InputCheckboxGroup.propTypes = {
	type: string,
	name: string.isRequired,
	label: string,
	description: string,
	value: array,
	required: bool,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	className: string,
};

export default InputCheckboxGroup;
